<template>
  <div class="error-original" v-if="!isProcessing">
    <div class="error-original__text">
      <h1 class="error-original__text__title">Error :(</h1>
      <p class="error-original__text__explain">We have an internal server error.</p>
      <v-btn class="error-original__text__btn" outlined rounded color="orange lighten-1" @click="moveList()">QRコードの一覧へ戻る</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error',
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * QRコードの一覧遷移
     * App.vueを読み込ませるためhrefを使用する
     */
    moveList () {
      window.location.href = '/list'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.error-original {
  position: relative;
  width: 100vw;
  height: 100vh;
  text-align: center;
  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__title {
      font-size: 6.4rem;
      color: $red_color;
    }
    &__explain {
      margin: 0;
      font-size: 1.5rem;
    }
    &__btn {
      margin-top: 20px;
    }
  }
}
</style>
